@font-face {
  font-family: 'Georgia';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/georgia-regular.woff') format('woff'),
    url('../fonts/georgia-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'opendyslexic';
  src: url('../fonts/opendyslexic-regular-webfont.woff2') format('woff2'),
    url('../fonts/opendyslexic-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/helvetica-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
