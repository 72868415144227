.notification__message {
  font-weight: 700;
}

.notification__close:after {
  font-size: 19px;
}

.notification__close {
  right: 5px;
  top: 5px;
}
